<template>
<div class="app">
  <Header/>
  <router-view></router-view>
  <Footer/>
</div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Header, Footer
  }
}
</script>

<style>
@import './assets/style.css'

</style>
