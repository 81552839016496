import Vue from 'vue';
import App from './App.vue';
import 'leaflet/dist/leaflet.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueAnimateOnScroll from 'vue-animate-onscroll';
import VueMeta from 'vue-meta';

import 'swiper/css/swiper.css';

Vue.use(VueAwesomeSwiper);
Vue.use(VueAnimateOnScroll);
Vue.use(VueMeta);

Vue.config.productionTip = false;

import { router } from './router/router';
import { leafletConfig } from './leafletConfig';

leafletConfig();

new Vue({
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  router
}).$mount('#app');
