<template>
  <header class="header">
    <ul class="nav" :class="{ openNav: openMobileNav }">
      <router-link
        tag="li"
        class="nav__item"
        to="/"
        exact-active-class="activeNav__item"
      >
        Anasayfa
      </router-link>
      <router-link
        tag="li"
        class="nav__item"
        active-class="activeNav__item"
        to="/hakkimizda"
      >
        Hakkımızda
      </router-link>
      <router-link
        tag="li"
        class="nav__item"
        active-class="activeNav__item"
        to="/urunler"
        >Ürünler</router-link
      >
      <router-link
        tag="li"
        class="nav__item"
        active-class="activeNav__item"
        to="/galeri"
        >Galeri</router-link
      >
    </ul>
    <div
      class="hamburger__menu"
      @click="clickMenu"
      :class="{ clickedMenu: openMobileNav }"
    >
      <span></span>
    </div>
    <img src="../assets/svg/logoYellow2.svg" alt="" class="header__logo" />
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      scroll: 0,
      openMobileNav: false
    };
  },
  methods: {
    clickMenu() {
      this.openMobileNav = !this.openMobileNav;
    }
  },
  watch: {
    $route: function() {
      this.openMobileNav = false;
    }
  }
};
</script>

<style></style>
