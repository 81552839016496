import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// import Home from '../components/pages/Home';
// import About from '../components/pages/About';
// import Products from '../components/pages/Products';
// import Services from '../components/pages/Services';
// import Gallery from '../components/pages/Gallery';
// import Contact from '../components/pages/Contact';

function lazyLoad(componentName) {
  return () => import(`@/components/pages/${componentName}.vue`);
}

export const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: lazyLoad('Home')
    },

    {
      path: '/hakkimizda',
      component: lazyLoad('About')
    },

    {
      path: '/urunler',
      component: lazyLoad('Products')
    },

    {
      path: '/galeri',
      component: lazyLoad('Gallery')
    },
    {
      path: '/urunler/:id',
      component: lazyLoad('ProductDetail')
    }
  ],
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
