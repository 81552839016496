<template>
  <div class="footer">
    <div class="footer__topContainer">
      <div class="footer__map">
        <l-map
          style="height: 100%; width: 100%"
          :zoom="zoom"
          :center="center"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
        >
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker :lat-lng="markerPosition"></l-marker>
        </l-map>
      </div>
      <div class="footer__info">
        <div class="footerInfo__container">
          <img src="../assets/svg/logoBlack2.svg" alt="" class="footer__logo" />
          <h3 class="footer__companyName">
            EMKA GRUP MAKİNA SANAYİ TİCARET LİMİTED ŞİRKETİ
          </h3>
          <div class="contactInfo__item">
            <label>Email:</label>
            <p>emkagrupmakina@gmail.com</p>
          </div>
          <div class="contactInfo__item">
            <label>Tel:</label>
            <p>0312 395 55 58</p>
          </div>
          <div class="contactInfo__item">
            <label>Ramazan Kayıhan:</label>
            <p>0535 448 60 16</p>
          </div>
          <div class="contactInfo__item">
            <label>Nuh Kayıhan:</label>
            <p>0533 411 91 73</p>
          </div>
          <div class="contactInfo__item">
            <label>Adres:</label>
            <p>İVEDİKOSB MAH. 1463 CAD. NO: 27 YENİMAHALLE/ ANKARA</p>
          </div>
          <div class="social__media">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__copyRight">
      <p>Copyright © 2021 Emka Grup Makina Sanayi | All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

export default {
  name: 'Footer',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 15,
      center: [39.9914491704738, 32.73116136992809],
      markerPosition: [39.9914491704738, 32.73116136992809],
      bounds: null
    };
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    }
  }
};
</script>

<style></style>
